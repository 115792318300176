export default [
  // 基础课
  {
    path: '/science',
    name: 'science',
    permission: 'base_course',
    component: () => import('@/view/body/body'),
    redirect: '/science/base',
    children: [
      {
        path: 'base',
        name: 'science-base',
        meta: {
          parent: 'science'
        },
        component: () => import('@/view/science')
        // component: require('@/view/science').default
      }
    ]
  },
  // 社团课
  {
    path: '/societies',
    name: 'societies',
    permission: 'club_course',
    component: () => import('@/view/body/body'),
    redirect: '/societies/course',
    children: [
      {
        path: 'course',
        name: 'Societies-course',
        meta: {
          parent: 'societies'
        },
        component: () => import('@/view/societies'),
        // component: require('@/view/societies').default,
        redirect: '/societies/course/guide',
        children: [
          // 备授课
          {
            path: 'prepare',
            name: 'Societies-prepare',
            meta: {
              parent: 'societies'
            },
            component: () => import('@/view/societies/prepare')
            // component: require('@/view/societies/prepare').default
          },
          {
            path: 'content',
            name: 'Societies-content',
            meta: {
              parent: 'societies',
              parentCnName: '课表',
              parentPath: '/societies/course/prepare',
              currentCnName: '课程内容'
            },
            // component: require('@/view/societies/societiesClass').default
            component: () => import('@/view/societies/courseStep')
          },
          // 社团班级
          {
            path: 'class',
            name: 'Societies-class',
            meta: {
              parent: 'societies'
            },
            // component: require('@/view/societies/societiesClass').default
            component: () => import('@/view/societies/societiesClass')
          },
          // 我的课程
          {
            path: 'my',
            name: 'Societies-my',
            meta: {
              parent: 'societies'
            },
            // component: require('@/view/societies/myCourse').default
            component: () => import('@/view/societies/myCourse')
          },
          // 课程详情
          {
            path: 'detail',
            name: 'Societies-classDetail',
            meta: {
              parent: 'societies',
              parentCnName: '课程库',
              parentPath: '/societies/course/guide',
              currentCnName: '详情'
            },
            // component: require('@/view/societies/classDetail').default
            component: () => import('@/view/societies/classDetail')
          },
          {
            path: 'chapter',
            name: 'Societies-chapter',
            meta: {
              parent: 'societies',
              parentCnName: '课程库',
              parentPath: '/societies/course/guide',
              currentCnName: '简介'
            },
            // component: require('@/view/societies/chapterView').default
            component: () => import('@/view/societies/chapterView')
          },
          {
            path: 'guide',
            name: 'course-guide',
            meta: {
              parent: 'societies',
              parentCnName: '课程库',
              parentPath: '/societies/course/guide',
              currentCnName: '课程库'
            },
            // component: require('@/view/societies/courseGuide').default
            component: () => import('@/view/societies/courseGuide')
          },
          // 全部课程
          {
            path: 'warehouse',
            name: 'Societies-warehouse',
            meta: {
              parent: 'societies',
              parentCnName: '课程库',
              parentPath: '/societies/course/guide',
              currentCnName: '全部课程'
            },
            // component: require('@/view/societies/classWarehouse').default
            component: () => import('@/view/societies/classWarehouse')
          },
          {
            path: 'courseCreateAndUpdate',
            name: 'Societies-courseCreateAndUpdate',
            meta: {
              parent: 'societies',
              parentCnName: '我的课程',
              parentPath: '/societies/course/my',
              currentCnName: '自建课程',
              subCnName: '编辑课程'
            },
            // component: require('@/view/societies/courseCreateAndUpdate').default
            component: () => import('@/view/societies/courseCreateAndUpdate')
          },
          {
            path: 'classRecord',
            name: 'Societies-classRecord',
            meta: {
              parent: 'societies',
              parentCnName: '社团班级',
              parentPath: '/societies/course/class',
              currentCnName: '课程记录'
            },
            // component: require('@/view/societies/classRecord').default
            component: () => import('@/view/societies/classRecord')
          },
          {
            path: 'selectCourse',
            name: 'Societies-selectCourse',
            meta: {
              parent: 'societies',
              parentCnName: '社团班级',
              parentPath: '/societies/course/class',
              currentCnName: '班级信息'
            },
            // component: require('@/view/societies/selectCourse').default
            component: () => import('@/view/societies/selectCourse')
          },
          {
            path: 'student',
            name: 'Societies-student',
            meta: {
              parent: 'societies',
              parentCnName: '社团班级',
              parentPath: '/societies/course/class',
              currentCnName: '学员管理'
            },
            // component: require('@/view/societies/student').default
            component: () => import('@/view/societies/student')
          },
          {
            path: 'schedule',
            name: 'Societies-schedule',
            meta: {
              parent: 'societies',
              parentCnName: '社团班级',
              parentPath: '/societies/course/class',
              currentCnName: '排课记录'
            },
            // component: require('@/view/societies/student').default
            component: () => import('@/view/societies/scheduleList')
          },
          {
            path: 'attendDetail',
            name: 'Societies-attendDetail',
            meta: {
              parent: 'societies',
              parentCnName: '排课记录',
              parentPath: '/societies/course/class',
              currentCnName: '假勤详情'
            },
            // component: require('@/view/societies/student').default
            component: () => import('@/view/societies/scheduleList/attendDetail')
          },
          {
            path: 'reviewList',
            name: 'Societies-reviewList',
            meta: {
              parent: 'societies',
              parentCnName: '排课记录',
              parentPath: '/societies/course/class',
              currentCnName: '评价列表'
            },
            // component: require('@/view/societies/student').default
            component: () => import('@/view/societies/scheduleList/reviewList')
          },
          {
            path: 'attendanceRecord',
            name: 'Societies-attendanceRecord',
            meta: {
              parent: 'societies',
              parentCnName: '社团班级',
              parentPath: '/societies/course/class',
              currentCnName: '考勤记录'
            },
            // component: require('@/view/societies/attendanceRecord').default
            component: () => import('@/view/societies/attendanceRecord')
          },
          {
            path: 'prepareCourse',
            name: 'Societies-prepareCourse',
            meta: {
              parent: 'societies',
              parentCnName: '备授课',
              parentPath: '/societies/course/prepare',
              currentCnName: '备授课资源'
            },
            // component: require('@/view/societies/prepareCourse').default
            component: () => import('@/view/societies/prepareCourse')
          }
        ]
      },
      // 查看我的课程
      {
        path: 'myCourseView',
        name: 'Societies-myCourseView',
        meta: {
          parent: 'societies',
          parentCnName: '我的课程',
          parentPath: '/societies/course/my',
          currentCnName: '课程详情'
        },
        // component: require('@/view/societies/myCourseView').default
        component: () => import('@/view/societies/myCourseView')
      }
    ]
  },
  // 资源
  {
    path: '/resources',
    name: 'Resource',
    permission: 'res_repo',
    //  component: () => import('@/view/body/body'),
    component: () => import('@/view/body/body'),
    children: [
      {
        path: 'cloud',
        name: 'Cloud',
        meta: {
          parent: 'search'
        },
        // component: require('@/view/resources/cloud/cloud').default
        component: () => import('@/view/resources/cloud/cloud')
      },
      {
        path: 'search',
        name: 'search',
        meta: {
          type: 'search'
        },
        // component: require('@/view/resources/cloud/cloud').default
        component: () => import('@/view/resources/cloud/searchPage')
      }
    ]
  },
  // 亲子任务
  {
    path: '/lab',
    name: 'lab',
    permission: 'edu_lab',
    //  component: () => import('@/view/body/body'),
    component: () => import('@/view/body/body'),
    redirect: '/lab/index/libs',
    children: [
      {
        path: 'index',
        name: 'lab-index',
        component: () => import('@/view/resources/lab'),
        // component: require('@/view/resources/lab/component/layout').default,
        children: [{
          // 家庭实验列表
          path: 'guide',
          name: 'Lab-guide',
          meta: {
            parent: 'lab'
          },
          component: () => import('@/view/resources/lab/list/guide')
          // component: require('@/view/resources/lab/list/list').default
        }, {
          // 家庭实验列表
          path: 'list',
          name: 'Lab-list',
          meta: {
            parent: 'lab'
          },
          component: () => import('@/view/resources/lab/list/list')
          // component: require('@/view/resources/lab/list/list').default
        }, {
          // 布置家庭实验
          path: 'assign',
          name: 'LabAssign',
          meta: {
            parent: 'lab'
          },
          component: () => import('@/view/resources/lab/assign/assign')
          // component: require('@/view/resources/lab/assign/assign').default
        }, {
          // 官方亲子任务库
          path: 'libs',
          name: 'standardLibs',
          meta: {
            parent: 'lab'
          },
          component: () => import('@/view/resources/lab/list/standardLibs')
        }, {
          // 官方亲子任务详情页
          path: 'detail',
          name: 'labDetail',
          meta: {
            parent: 'lab'
          },
          component: () => import('@/view/resources/lab/list/labDetail')
        }, {
          // 批阅
          path: 'review',
          name: 'LabReview',
          meta: {
            parent: 'lab',
            parentPath: './list'
          },
          component: () => import('@/view/resources/lab/review/review')
          // component: require('@/view/resources/lab/review/review').default
        }, {
          // 批阅详情
          path: 'review-detail',
          name: 'LabReviewDetail',
          meta: {
            parent: 'lab'
          },
          component: () => import('@/view/resources/lab/reviewDetail/reviewDetail')
          // component: require('@/view/resources/lab/reviewDetail/reviewDetail').default
        }]
      }
    ]
  },
  // 教师研修
  {
    path: '/teacherCourse',
    name: 'teacherCourse',
    permission: 'edu_training',
    component: () => import('@/view/body/body'),
    redirect: '/teacherCourse/list',
    children: [
      {
        path: 'index',
        name: 'teacherCourseIndex',
        meta: {
          parent: 'teacherCourse'
        },
        // component: require('@/view/resources/cloud/cloud').default
        component: () => import('@/view/teacherCourse/index'),
        children: [
          {
            path: '/teacherCourse/more/:type',
            name: 'teacherCourseMore',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '研修列表'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/list')
          },
          {
            path: '/teacherCourse/list',
            name: 'teacherCourseList',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '研修列表'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/list/trainingList')
          },
          {
            path: '/teacherCourse/morelist',
            name: 'teacherCoursemoreList',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '研修列表'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/list/moreList')
          },
          {
            path: '/teacherCourse/about',
            name: 'myAbout',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '个人成长'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/about/index'),
            redirect: '/teacherCourse/myList',
            children: [
              {
                path: '/teacherCourse/myList',
                name: 'myTrainingList',
                meta: {
                  parent: 'teacherCourse',
                  parentCnName: '教学成长',
                  parentPath: '/teacherCourse',
                  currentCnName: '我的研修'
                },
                // component: require('@/view/resources/cloud/cloud').default
                component: () => import('@/view/teacherCourse/list/myList')
              },
              {
                path: '/teacherCourse/activity/my',
                name: 'teacherActivityMy',
                meta: {
                  parent: 'teacherCourse',
                  parentCnName: '教学成长',
                  parentPath: '/teacherCourse',
                  currentCnName: '我的活动'
                },
                component: () => import('@/view/teacherCourse/activity/my.vue')
              },
              {
                path: '/teacherCourse/myAsk',
                name: 'teacherMyAsk',
                meta: {
                  parent: 'teacherCourse',
                  parentCnName: '教学成长',
                  parentPath: '/teacherCourse',
                  currentCnName: '我的问题'
                },
                component: () => import('@/view/teacherCourse/myAsk/index.vue')
              },
              {
                path: '/teacherCourse/myAnswer',
                name: 'teacherMyAnswer',
                meta: {
                  parent: 'teacherCourse',
                  parentCnName: '教学成长',
                  parentPath: '/teacherCourse',
                  currentCnName: '我的回答'
                },
                component: () => import('@/view/teacherCourse/myAnswer/index.vue')
              }
            ]
          },
          {
            path: '/teacherCourse/science',
            name: 'scienceQA',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '科学答疑'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/scienceQA/index')
          },
          {
            path: '/teacherCourse/qtDetail',
            name: 'teacherQtDetail',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '问题详情'
            },
            component: () => import('@/view/teacherCourse/about/qtDetail/index.vue')
          },
          {
            path: '/teacherCourse/answer',
            name: 'teacherQtDetail',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '回答页面'
            },
            component: () => import('@/view/teacherCourse/about/answer/index.vue')
          },
          {
            path: '/teacherCourse/aigc',
            name: 'aigc',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '问AI',
              parentPath: '/teacherCourse',
              currentCnName: '科学答疑'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/aigc/text-to-text')
          },
          {
            path: 'courseDetail',
            name: 'teacherCourseCourseDetail',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '课程详情'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/detail/courseDetail')
          },
          {
            path: 'trainingDetail',
            name: 'teacherCourseTrainingDetail',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '研修详情'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/detail/trainingDetail')
          },
          {
            path: 'trainingCourse',
            name: 'teacherCourseTrainingCourse',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '研修课程'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/detail/trainingCourse')
          },
          {
            path: '/teacherCourse/activity/list',
            name: 'teacherActivityList',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '推荐活动'
            },
            component: () => import('@/view/teacherCourse/activity/index.vue')
          },
          {
            path: '/teacherCourse/activity/detail',
            name: 'teacherCourseActivityDetail',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '研修详情'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/activity/activityDetail.vue')
          },
          {
            path: '/teacherCourse/inform/detail',
            name: 'teacherCourseInformDetail',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '通知详情'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/activity/informDetail.vue')
          },
          {
            path: '/teacherCourse/inform/submit',
            name: 'teacherCourseActivitySubmit',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '申报页面'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/activity/submitView.vue')
          },
          {
            path: '/teacherCourse/activity/morelist',
            name: 'teacherCourseActivityMoreList',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '更多活动'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/activity/moreList')
          },
          {
            path: '/teacherCourse/thesis/index',
            name: 'thesis',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '论文期刊'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/thesis/index')
          },
          {
            path: '/teacherCourse/thesis/detail',
            name: 'thesisDetail',
            meta: {
              parent: 'teacherCourse',
              parentCnName: '教学成长',
              parentPath: '/teacherCourse',
              currentCnName: '论文期刊详情'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/teacherCourse/thesis/detail.vue')
          }
        ]
      }
    ]
  },
  // 校本教材
  {
    path: '/schoolRes',
    name: 'schoolRes',
    permission: 'schl_course',
    component: () => import('@/view/body/body'),
    redirect: '/schoolRes/index',
    children: [
      {
        path: 'index',
        name: 'science-index',
        meta: {
          parent: 'schoolRes'
        },
        component: () => import('@/view/science')
      }
    ]
  },
  // 直播天地
  {
    path: '/live',
    name: 'live',
    permission: 'edu_live',
    component: () => import('@/view/body/body'),
    redirect: '/live/index/list',
    children: [
      {
        path: 'index',
        name: 'LiveIndex',
        meta: {
          parent: 'live'
        },
        // component: require('@/view/resources/cloud/cloud').default
        component: () => import('@/view/live/index'),
        children: [
          {
            path: 'plan',
            name: 'LivePlan',
            meta: {
              parent: 'live'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/live/livePlan')
          },
          {
            path: 'list',
            name: 'LiveList',
            meta: {
              parent: 'live'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/live/List/liveList')
          },
          {
            path: 'more',
            name: 'LiveMore',
            meta: {
              parent: 'live'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/live/List')
          },
          {
            path: 'seriesDetail',
            name: 'seriesDetail',
            meta: {
              parent: 'live'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/live/List/seriesList')
          },
          {
            path: 'my',
            name: 'LiveMy',
            meta: {
              parent: 'live'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/live/myLive')
          },
          {
            path: 'replayDetail',
            name: 'ReplayDetail',
            meta: {
              parent: 'live',
              parentCnName: '直播天地',
              parentPath: '/live',
              currentCnName: '回放详情'
            },
            component: () => import('@/view/live/detail/replayDetail.vue')
          }
        ]
      },
      {
        path: 'liveMore',
        name: 'LiveMore',
        meta: {
          parent: 'live',
          parentCnName: '直播天地',
          parentPath: '/live',
          currentCnName: '更多直播'
        },
        component: () => import('@/view/live/more/liveMore.vue')
      },
      {
        path: 'replayMore',
        name: 'ReplayMore',
        meta: {
          parent: 'live',
          parentCnName: '直播天地',
          parentPath: '/live',
          currentCnName: '更多回放'
        },
        component: () => import('@/view/live/more/replayMore.vue')
      }
    ]
  },
  // 实验专区
  {
    path: '/experiment',
    name: 'experiment',
    permission: 'edu_experiment',
    component: () => import('@/view/body/body'),
    redirect: '/experiment/index/class',
    children: [
      {
        path: 'index',
        name: 'ExprimentIndex',
        meta: {
          parent: 'experiment'
        },
        // component: require('@/view/resources/cloud/cloud').default
        component: () => import('@/view/experiment/index'),
        children: [
          {
            path: 'class',
            name: 'ClassExpriment',
            meta: {
              parent: 'experiment'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/experiment/class')
          },
          {
            path: 'controls',
            name: 'ControlsExpriment',
            meta: {
              parent: 'experiment'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/experiment/controls')
          }
        ]
      }
    ]
  },
  // 商城
  {
    path: '/mall',
    name: 'mall',
    permission: 'edu_mall',
    component: () => import('@/view/body/body'),
    redirect: '/mall/index',
    children: [
      {
        path: 'index',
        name: 'MallIndex',
        meta: {
          parent: 'mall'
        },
        // component: require('@/view/resources/cloud/cloud').default
        component: () => import('@/view/mall/index/index.vue')
      },
      {
        path: 'detail',
        name: 'MallDetail',
        meta: {
          parent: 'mall',
          parentCnName: '商城',
          parentPath: '/mall',
          currentCnName: '商品详情'
        },
        // component: require('@/view/resources/cloud/cloud').default
        component: () => import('@/view/mall/detail')
      }
    ]
  },
  // 专家专栏
  {
    path: '/expert',
    name: 'expert',
    permission: 'edu_expert',
    component: () => import('@/view/body/body'),
    redirect: '/expert/groups/1',
    children: [
      {
        path: 'index',
        name: 'ExpertIndex',
        meta: {
          parent: 'expert'
        },
        component: () => import('@/view/expert/index.vue'),
        children: [
          {
            path: '/expert/index/:type',
            name: 'expertModule',
            meta: {
              parent: 'expert',
              parentCnName: '专家专栏',
              parentPath: '/expert',
              currentCnName: '专家其他模块'
            },
            component: () => import('@/view/expert/otherM.vue')
          },
          {
            path: '/expert/groups/:id',
            name: 'expertGroups',
            meta: {
              parent: 'expert',
              parentCnName: '专家专栏',
              parentPath: '/groups',
              currentCnName: '专家团队'
            },
            component: () => import('@/view/expert/teamMore')
          },
          {
            path: '/expert/teamDetail',
            name: 'teamDetail',
            meta: {
              parent: 'expert',
              parentCnName: '专家专栏',
              parentPath: '/teamDetail',
              currentCnName: '团队详情'
            },
            component: () => import('@/view/expert/teamDetail')
          },
          {
            path: '/expert/teamMore',
            name: 'teamMore',
            meta: {
              parent: 'expert',
              parentCnName: '专家专栏',
              parentPath: '/teamMore',
              currentCnName: '团队更多'
            },
            component: () => import('@/view/expert/teamMore')
          },
          {
            path: '/expert/space',
            name: 'expertSpace',
            meta: {
              parent: 'expert',
              parentCnName: '专家专栏',
              parentPath: '/expert',
              currentCnName: '专家空间'
            },
            component: () => import('@/view/expert/space')
          },
          {
            path: '/expert/more',
            name: 'expertMore',
            meta: {
              parent: 'expert',
              parentCnName: '专家专栏',
              parentPath: '/expert',
              currentCnName: '专家空间更多'
            },
            component: () => import('@/view/expert/more')
          },
          {
            path: '/expert/detail',
            name: 'expertDetail',
            meta: {
              parent: 'expert',
              parentCnName: '专家专栏',
              parentPath: '/expert',
              currentCnName: '专家详情'
            },
            component: () => import('@/view/expert/detail')
          },
          {
            path: '/expert/resMore',
            name: 'resMore',
            meta: {
              parent: 'expert',
              parentCnName: '专家专栏',
              parentPath: '/resMore',
              currentCnName: '更多'
            },
            component: () => import('@/view/expert/resMore')
          }
        ]
      }
    ]
  },
  // 试题试卷
  {
    path: '/iac',
    name: 'iac',
    permission: 'edu_paper',
    component: () => import('@/view/body/body'),
    redirect: '/iac/paper',
    children: [
      {
        path: 'paper',
        name: 'iacPaper',
        component: () => import('@/view/testPaper/layout/index'),
        redirect: '/iac/paper/myQuestion',
        children: [
          {
            path: 'question',
            name: 'paper-questions',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '试题库'
            },
            component: () => import('@/view/testPaper/questions')
          },
          {
            path: 'list',
            name: 'paper-list',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '试卷库'
            },
            component: () => import('@/view/testPaper/list')
          },
          {
            path: 'myQuestion',
            name: 'paper-my-question',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '我的试题'
            },
            component: () => import('@/view/testPaper/myQuestion')
          },
          {
            path: 'myPaper',
            name: 'paper-my-paper',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '我的试卷'
            },
            component: () => import('@/view/testPaper/myPaper')
          },
          {
            path: 'publish',
            name: 'publish-record',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '发布记录'
            },
            component: () => import('@/view/testPaper/publishRecord')
          },
          {
            path: 'wrongTopics',
            name: 'wrong-topics',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '班级错题库'
            },
            component: () => import('@/view/testPaper/wrongTopics')
          },
          {
            path: 'create',
            name: 'paper-create',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '创建试卷'
            },
            component: () => import('@/view/testPaper/subPages/createPaper')
          },
          {
            path: 'edit',
            name: 'paper-edit',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '编辑试卷'
            },
            component: () => import('@/view/testPaper/subPages/editPaper')
          },
          {
            path: 'report',
            name: 'publish-report',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '学情报告'
            },
            component: () => import('@/view/testPaper/paperReport')
          },
          {
            path: 'target',
            name: 'target-paper',
            meta: {
              parent: 'iacPaper',
              parentCnName: '试卷试题',
              parentPath: '/iac/paper/question',
              currentCnName: '靶向组卷'
            },
            component: () => import('@/view/testPaper/subPages/targetPaper')
          }
        ]
      }
    ]
  },
  // AI+
  {
    path: '/AI',
    name: 'AI',
    permission: 'edu_AI',
    component: () => import('@/view/body/body'),
    redirect: '/AI/index/answers',
    children: [
      {
        path: 'index',
        name: 'AIIndex',
        meta: {
          parent: 'AI'
        },
        // component: require('@/view/resources/cloud/cloud').default
        component: () => import('@/view/AI/index'),
        children: [
          {
            path: 'answers',
            name: 'AIAnswers',
            meta: {
              parent: 'AI'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/AI/answers')
          },
          {
            path: 'teachingPlan',
            name: 'AITeachingPlan',
            meta: {
              parent: 'AI'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/AI/teachingPlan')
          },
          {
            path: 'courseware',
            name: 'AICourseware',
            meta: {
              parent: 'AI'
            },
            // component: require('@/view/resources/cloud/cloud').default
            component: () => import('@/view/AI/courseware')
          }
        ]
      }
    ]
  }
]
