import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { getToken } from '../storage/viewStorage'

const whiteList = [
  'websitehome',
  'bindUser',
  'Downcenter',
  'Product',
  'Usercase',
  'login',
  'register',
  'pcLogin',
  'pcResetpwd',
  'pcRegister'
]
Vue.use(Router)
const vueRouter = new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    // 官网
    {
      path: '/',
      meta: {
        isAuth: false
      },
      component: () => import('@/view/website/index'),
      redirect: '/websitehome',
      children: [
        {
          path: '/websitehome',
          name: 'websitehome',
          component: () => import('@/view/website/home'),
          meta: {
            isAuth: false
          }
        },
        {
          path: '/downcenter',
          name: 'Downcenter',
          component: () => import('@/view/website/downcenter'),
          meta: {
            isAuth: false
          }
        },
        {
          path: '/usercase/:id',
          name: 'Usercase',
          component: () => import('@/view/website/usercase'),
          meta: {
            isAuth: false
          }
        },
        {
          path: '/product/:id',
          name: 'Product',
          component: () => import('@/view/website/product'),
          meta: {
            isAuth: false
          }
        }
      ]
    },
    // 主页
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/view/body/body'),
      redirect: '/main',
      children: [
        {
          path: '/main',
          name: 'mainPage',
          component: () => import('@/view/notice/homePage'),
          meta: {
            type: 'notice'
          }
        },
        {
          path: '/notice',
          name: 'mainPage',
          component: () => import('@/view/notice/notice'),
          meta: {
            type: 'notice'
          }
        },
        {
          path: '/pages/home/sub/details',
          // path: '/detail',
          name: 'mainPage',
          meta: {
            parent: 'mainPage',
            parentCnName: '动态',
            parentPath: '/notice/more',
            currentCnName: '动态详情',
            type: 'notice'
          },
          component: () => import('@/view/notice/detail')
        },
        {
          path: '/home/contactDetail',
          // path: '/detail',
          name: 'mainPage',
          meta: {
            parent: 'notice',
            parentCnName: '首页',
            parentPath: '/home',
            currentCnName: '联系我们'
          },
          component: () => import('@/view/notice/detail')
        // component: require('@/view/notice/detail').default
        },
        {
          path: '/home/solutionDetail',
          // path: '/detail',
          name: 'mainPage',
          meta: {
            parent: 'notice',
            parentCnName: '首页',
            parentPath: '/home',
            currentCnName: '产品及解决方案',
            isAuth: false
          },
          component: () => import('@/view/notice/solutionList/detail')
        // component: require('@/view/notice/detail').default
        },
        {
          path: '/message',
          name: 'mainPage',
          meta: {
            type: 'message'
          },
          component: () => import('@/view/ucenter/message/index')
        // component: require('@/view/ucenter/message/index').default
        },
        {
          path: '/addon',
          name: 'Addon',
          meta: {
            type: 'addon',
            isAuth: false
          },
          component: () => import('@/view/addon/index')
        },
        {
          path: '/addon/:addonId',
          name: 'AddonDetail',
          meta: {
            type: 'addondetail',
            isAuth: false
          },
          component: () => import('@/view/addon/detail')
        },
        {
          path: '/faq',
          name: 'mainPage',
          component: () => import('@/view/notice/FaqAssist'),
          meta: {
            parentCnName: '首页',
            parentPath: '/home',
            currentCnName: '动态'
          }
        },
        {
          path: '/notice/more',
          name: 'mainPage',
          meta: {
            parent: 'notice',
            parentCnName: '首页',
            parentPath: '/home',
            currentCnName: '动态'
          },
          component: () => import('@/view/notice/more')
        }
      ]
    },
    // 新手引导
    {
      path: '/guide',
      meta: {
        isAuth: false
      },
      component: () => import('@/view/ucenter/guide/index')
    },
    // 登录
    {
      path: '/login',
      name: 'Login',
      meta: {
        isAuth: false
      },
      component: () => import(/* webpackChunkName: 'loginWrap' */'@/view/login/layout'),
      redirect: '/login',
      children: [
        {
          path: '/login',
          name: 'login',
          meta: {
            isAuth: false
          },
          component: () => import(/* webpackChunkName: 'login' */'@/view/login/login')
        },
        {
          path: '/register',
          name: 'register',
          meta: {
            isAuth: false
          },
          component: () => import('@/view/login/register')
        },
        {
          path: '/resetpwd',
          name: 'resetpwd',
          meta: {
            isAuth: false
          },
          component: () => import('@/view/login/resetpwd')
        }
      ]
    },
    {
      path: '/ssoAuth',
      meta: {
        isAuth: false
      },
      component: () => import('@/view/login/ssoAuth')
    },
    // pc登录
    {
      path: '/pclogin',
      name: 'pcLogin',
      meta: {
        isAuth: false
      },
      component: () => import(/* webpackChunkName: 'loginWrap' */'@/view/pcLogin/layout'),
      redirect: '/pclogin',
      children: [
        {
          path: '/pclogin',
          name: 'pcLogin',
          meta: {
            isAuth: false
          },
          component: () => import(/* webpackChunkName: 'login' */'@/view/pcLogin/login')
        },
        {
          path: '/pcregister',
          name: 'pcRegister',
          meta: {
            isAuth: false
          },
          component: () => import('@/view/pcLogin/register')
        },
        {
          path: '/pcresetpwd',
          name: 'pcResetpwd',
          meta: {
            isAuth: false
          },
          component: () => import('@/view/pcLogin/resetpwd')
        }
      ]
    },
    // 图片集
    {
      path: '/component-imgs',
      name: 'componentImgs',
      component: () => import('@/view/windows/component-imgs.vue')
    },
    {
      path: '/iframe',
      name: 'iframe',
      component: () => import('@/view/windows/iframe.vue')
    },
    // 在线浏览文件
    {
      path: '/onlineFile',
      name: 'onlineFile',
      component: () => import('@/view/windows/onlineFile.vue')
    },
    {
      path: '/text',
      name: 'text',
      component: () => import('@/view/windows/text.vue')
    },
    // 在线浏览视频
    {
      path: '/onlineVideo',
      name: 'onlineVideo',
      component: () => import('@/view/windows/onlineVideo.vue')
    },
    // 个人空间
    {
      path: '/user-space',
      name: 'space',
      component: () => import('@/view/ucenter/space/index.vue')
    },
    // 个人空间
    {
      path: '/expert-space',
      name: 'expertSpace',
      component: () => import('@/view/expert/space/index.vue')
    },
    // 图片
    {
      path: '/img',
      name: 'Img',
      component: () => import('@/view/windows/img.vue')
    },
    // 单独配置协议模块
    {
      path: '/agreement',
      name: 'agreement',
      meta: {
        isAuth: false
      },
      component: () => import('@/view/login/agreement')
    },
    // 个人空间
    {
      path: '/user-space',
      name: 'space',
      component: () => import('@/view/ucenter/space/index.vue'),
      meta: {
        isAuth: false
      }
    },
    // 上课
    {
      path: '/class',
      name: 'ClassPage',
      component: () => import('@/view/class/index.vue')
    },
    // 上课签到
    {
      path: '/signClass',
      name: 'signClassPage',
      component: () => import('@/view/class/signClass/index.vue')
    },
    // 检测结果列表
    {
      path: '/outcome',
      name: 'outcomeListPage',
      component: () => import('@/view/class/testOutcome/index.vue')
    },
    // 学生检测结果预览
    {
      path: '/outcome/watch',
      name: 'test-outcome-watch',
      component: () => import('@/view/class/testOutcome/testWatch.vue')
    },
    // 学生单题结果数据展示
    {
      path: '/outcome/singleDataView',
      name: 'single-data-view',
      component: () => import('@/view/class/testOutcome/singleDataView.vue')
    },
    // 学生结果数据展示
    {
      path: '/outcome/dataView',
      name: 'paper-data-view',
      component: () => import('@/view/class/testOutcome/testDataView.vue')
    },
    // 检测结果列表
    {
      path: '/outcome',
      name: 'test-outcome',
      component: () => import('@/view/class/testOutcome/index.vue')
    },
    // 用户绑定手机号码
    {
      path: '/bindUser',
      name: 'bindUser',
      component: () => import('@/view/bindUser/index.vue'),
      meta: {
        isAuth: false
      }
    },
    // 上课资源
    {
      path: '/classRes',
      name: 'classRes',
      component: () => import('@/view/class/view.vue')
    },
    // 上课工具：倒计时
    {
      path: '/countdown',
      name: 'Countdown',
      component: () => import('@/view/class/countdown.vue'),
      meta: {
        isAuth: false
      }
    },
    // 用户中心
    {
      path: '/ucenter',
      name: 'ucenter',
      component: () => import('@/view/body/body'),
      // redirect: '/ucenter/account/profile',
      children: [
        {
          path: 'class/list',
          name: 'classlist',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心',
            parentPath: '/ucenter'
          },
          component: () => import('@/view/ucenter/class/list')
        // component: require('@/view/ucenter/class/list').default
        },
        {
          path: 'class/timetable',
          name: 'classtimetable',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心'
          },
          component: () => import('@/view/ucenter/class/timetable')
        // component: require('@/view/ucenter/class/timetable').default
        },
        {
          path: 'student/list',
          name: 'studentlist',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心'
          },
          component: () => import('@/view/ucenter/student/list')
        // component: require('@/view/ucenter/student/list').default
        },
        {
          path: 'account/feedback',
          name: 'accountFeedback',
          meta: {
            type: 'ucenter'
          },
          component: () => import('@/view/ucenter/account/feedback')
        // component: require('@/view/ucenter/account/feedback').default
        },
        {
          path: 'point/point',
          name: 'accountPoint',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心',
            parentPath: '/ucenter',
            currentCnName: '我的积分'
          },
          component: () => import('@/view/ucenter/point/point')
        // component: require('@/view/ucenter/point/point').default
        },
        {
          path: 'account/profile',
          name: 'accountProfile',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心',
            parentPath: '/ucenter',
            currentCnName: '个人信息'
          },
          component: () => import('@/view/ucenter/account/profile')
        // component: require('@/view/ucenter/account/profile').default
        },
        {
          path: 'publish/publish',
          name: 'accountPublish',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心',
            parentPath: '/ucenter',
            currentCnName: '个人信息'
          },
          component: () => import('@/view/ucenter/publish/publish')
        },
        {
          path: 'resource/resource',
          name: 'accountResource',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心',
            parentPath: '/ucenter',
            currentCnName: '我的发布'
          },
          component: () => import('@/view/ucenter/resource/resource')
        },
        {
          path: 'account/space',
          component: () => import('@/view/ucenter/space'),
          name: 'accountSpace',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心',
            parentPath: '/ucenter',
            currentCnName: '个人空间'
          }
        },
        {
          path: 'account/security',
          name: 'accountsecurity',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心',
            parentPath: '/ucenter',
            currentCnName: '账号安全'
          },
          component: () => import('@/view/ucenter/account/security')
        // component: require('@/view/ucenter/account/security').default
        },
        {
          path: 'aboutus',
          name: 'aboutus',
          meta: {
            type: 'ucenter',
            parent: 'ucenter',
            parentCnName: '个人中心',
            parentPath: '/ucenter',
            currentCnName: '关于我们'
          },
          component: () => import('@/view/ucenter/aboutus/aboutus')
        // component: require('@/view/ucenter/aboutus/aboutus').default
        }

      ]
    },
    // 上课工具：点名
    {
      path: '/roll-call',
      name: 'RollCall',
      component: () => import('@/view/class/roll-call.vue'),
      meta: {
        isAuth: false
      }
    },
    // 上课工具：课堂记录
    {
      path: '/course-record',
      name: 'course-record',
      component: () => import('@/view/class/classRecord')
    },
    // 上课工具：课堂考勤
    {
      path: '/course-attend',
      name: 'course-ettend',
      component: () => import('@/view/class/classAttend')
    },
    // 推广活动页面
    {
      path: '/l/:type/:id',
      meta: {
        isAuth: false
      },
      component: () => import('@/view/promotion/index')
    },
    {
      path: '/p/:id',
      meta: {
        isAuth: false
      },
      component: () => import('@/view/questionnaire/index')
    },
    {
      path: '/p/e/:id',
      meta: {
        isAuth: false
      },
      component: () => import('@/view/questionnaire/questionnaire_edit')
    },
    {
      path: '/p/d/:id',
      meta: {
        isAuth: false
      },
      component: () => import('@/view/questionnaire/questionnaire_data')
    }
    // {
    //   path: '*',
    //   redirect: '/'
    // }
  ]
})
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = vueRouter.push
const originalReplace = vueRouter.replace
// push
vueRouter.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => console.log(err))
}

// replace
vueRouter.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) { return originalReplace.call(this, location, onResolve, onReject) }
  return originalReplace.call(this, location).catch(err => err)
}

vueRouter.beforeEach(async (to, from, next) => {
  const flag = navigator.userAgent.indexOf('Electron') > -1
  if (to.name === 'login' && flag) {
    next('/pcLogin')
    return
  }

  // 百度统计支持
  if (window._hmt && to.path) {
    window._hmt.push(['_trackPageview', '#' + to.fullPath])
    // console.log(['_trackPageview',  to.fullPath], to)
  }

  if (whiteList.findIndex(val => val === to.name) > -1) {
    next()
    return
  }
  if (!getToken()) {
    if (to.meta.isAuth === false) {
      next()
    } else {
      if (flag) {
        next('/pcLogin')
      } else {
        if (to.query && to.query[0]) {
          next(`/login?${Object.keys(to.query)[0]}=${Object.values(to.query)[0]}`)
        } else {
          next('/login')
        }
      }
    }
  } else {
    if (!store.state.mqtt.client) {
      store.commit('mqtt/init')
    }
    if (to.meta.isAuth === false) {
      next()
      return
    }
    if (!store.state.permission.isSetMenuPermission) {
      // 未设置权限需
      store.dispatch('permission/getUserAuthFn').then(routes => {
        vueRouter.addRoutes(routes)

        // 资源展示
        if (store.state.baseCourse.showPlayResources) {
          store.commit('baseCourse/setPlayRes', false)
          next({ ...from, replace: true })
        } else {
          store.commit('baseCourse/setPlayRes', false)
          next({ ...to, replace: true })
        }
      })
    } else {
      const homePageName = store.state.permission.homePageName
      if (to.name === 'mainPage' && homePageName !== 'Home') {
        next({
          name: homePageName
        })
        return
      }
      if (store.state.baseCourse.showPlayResources) {
        store.commit('baseCourse/setPlayRes', false)
        next({ ...from, replace: true })
      } else {
        store.commit('baseCourse/setPlayRes', false)
        next()
      }
    }
  }
})

export default vueRouter
