// 基础课数据
//  getCookie, setCookie
import { setItem, getItem, removeItem, getMachineIden } from '@/storage/viewStorage'
import {
  treeselect,
  selectUerResResourceList,
  getResouceNumByTypeApi,
  getClubResouceNumByTypeApi,
  getStanderClubResouceNumByTypeApi
} from '@/api/resources'

const schlCourse = {
  namespaced: true,
  state: {
    slideData: undefined, // 基础课侧边栏数据
    resource: [], // 基础课资源列表
    isLeftTrigger: false,
    isCacheType: 'nomal', // 基础课页面类型
    schlSideMenuStatus: {
      openName: [],
      activeName: ''
    }, // 基础课数据配置
    SCHLCOURSEPARAMS: {}, // 资源请求数据
    CurrentClassName: '', // 当前课程名称
    numGroup: {}, // 资源分类数量
    courseId: undefined,
    courseName: undefined,
    chapterId: undefined
  },
  mutations: {
    setSlideDataFn (state, data) {
      state.slideData = data
    },
    setSideMenuStatusFn (state, data) {
      state.schlSideMenuStatus = data
      setItem('schlSideMenuStatus', state.schlSideMenuStatus ? JSON.stringify(state.schlSideMenuStatus) : '')
    },
    setResParamsFn (state, data) {
      if (typeof data === 'string') {
        return
      }
      state.SCHLCOURSEPARAMS = Object.assign(state.SCHLCOURSEPARAMS, data)
      setItem('SCHLCOURSEPARAMS', JSON.stringify(state.SCHLCOURSEPARAMS))
    },
    setResourceFn (state, data) {
      state.resource = data
    },
    setLeftTrigger (state, data) {
      state.isLeftTrigger = data
    },
    setPageType (state, data) {
      state.isCacheType = data
    },
    setCurrentClassName (state, data) {
      state.CurrentClassName = data
    },
    setNumGroup (state, data) {
      state.numGroup = data
    },
    // 清空基础课记录数据
    removeDataFn (state, data) {
      removeItem('schlSideMenuStatus')
      removeItem('SCHLCOURSEPARAMS')
    },
    saveCourseInfo (state, data) {
      state.courseId = data.courseId
      setItem('SCHLCOURSEID', data.courseId)
      state.chapterId = data.chapterId
      if (data.chapterId) setItem('SCHLCHAPTERID', data.chapterId)
    }
  },
  actions: {
    // 获取校本侧边栏数据
    getSchlCourseDataFn ({ commit, state }, courseId) {
      const SCHLCOURSEPARAMS = getItem('SCHLCOURSEPARAMS')
      const params = SCHLCOURSEPARAMS ? (typeof SCHLCOURSEPARAMS === 'string' ? JSON.parse(SCHLCOURSEPARAMS) : SCHLCOURSEPARAMS) : {}
      if (courseId) {
        params.courseId = courseId
      } else if (getItem('SCHLCOURSEID')) {
        params.courseId = getItem('SCHLCOURSEID')
      }
      if (params.courseId) {
        return treeselect(params)
          .then(res => {
            const slideData = res.data.data
            commit('setSlideDataFn', slideData)
            return slideData
          })
          .then((chapterList) => {
            let schlSideMenuStatus = getItem('schlSideMenuStatus')
            schlSideMenuStatus = schlSideMenuStatus === '' ? '' : (typeof schlSideMenuStatus === 'string' ? JSON.parse(schlSideMenuStatus) : schlSideMenuStatus)
            const _sideMenuStatus = {}
            let currentClassName = ''
            if (typeof schlSideMenuStatus === 'string' || !schlSideMenuStatus || schlSideMenuStatus === '' || !schlSideMenuStatus.openName || !schlSideMenuStatus.openName.length) {
              const children = (chapterList[0] && chapterList[0].children) || [{
                id: 0,
                label: ''
              }]
              _sideMenuStatus.activeName = children[0].id
              currentClassName = children[0].label
              _sideMenuStatus.openName = [(chapterList[0] && chapterList[0].id) || '']
            } else {
              const sideStatus = schlSideMenuStatus
              _sideMenuStatus.activeName = sideStatus.activeName
              _sideMenuStatus.openName = sideStatus.openName
              if (!chapterList.some(item => item.id === _sideMenuStatus.openName[0])) {
                _sideMenuStatus.openName = [(chapterList[0] && chapterList[0].id) || '']
                _sideMenuStatus.activeName = (chapterList[0] && chapterList[0].children[0].id) || ''
                currentClassName = (chapterList[0] && chapterList[0].children[0].label) || ''
              } else {
                const menu = chapterList.find(item => item.id === _sideMenuStatus.openName[0]) || []
                const section = menu.children.find(item => item.id === _sideMenuStatus.activeName) || {}
                _sideMenuStatus.openName = [menu.id]
                _sideMenuStatus.activeName = section.id
                currentClassName = section.label
              }
            }
            params.chapterId = _sideMenuStatus.activeName
            params.parentChapterId = _sideMenuStatus.openName[0]
            commit('setSideMenuStatusFn', _sideMenuStatus)
            commit('setCurrentClassName', currentClassName)
            commit('setResParamsFn', params)
            return params
          })
      }
    },
    // 获取基础课基础数据
    getResourceDataFn ({ commit, state }) {
      if (!state.SCHLCOURSEPARAMS.machineIden) {
        state.SCHLCOURSEPARAMS.machineIden = getMachineIden()
      }
      state.SCHLCOURSEPARAMS.courseType = 4
      return selectUerResResourceList(state.SCHLCOURSEPARAMS).then(res => {
        const data = res.data.rows
        commit('setResourceFn', data)
        return data
      })
    },
    // 获取基础课分类资源数据
    getResouceNumByTypeFn ({ commit, state }, params) {
      const _params = Object.assign({}, params)
      _params.parentChapterId = undefined
      _params.resType = undefined
      _params.courseType = 4
      if (!_params.machineIden) {
        _params.machineIden = getMachineIden()
      }
      _params.resourceQueryType = params.resourceQueryType || 2
      getResouceNumByTypeApi(_params).then(res => {
        const data = res.data.data
        commit('setNumGroup', data)
      })
    },
    // 获取课后服务分类资源数据
    getClubResouceNumByTypeFn ({ commit }, condition) {
      const params = Object.assign({}, condition)
      params.parentChapterId = undefined
      params.resType = undefined
      getClubResouceNumByTypeApi(params).then(res => {
        const data = res.data.data
        commit('setNumGroup', data)
      })
    },
    // 获取课后服务分类资源数据
    getStanderClubResouceNumByTypeFn ({ commit }, condition) {
      const params = Object.assign({}, condition)
      params.parentChapterId = undefined
      params.resType = undefined
      getStanderClubResouceNumByTypeApi(params).then(res => {
        const data = res.data.data
        commit('setNumGroup', data)
      })
    }
  }
}

export default schlCourse
