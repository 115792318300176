// 基础课数据
//  getCookie, setCookie
import { setItem, getItem, removeItem, getCourseId, getMachineIden } from '@/storage/viewStorage'

import {
  treeselect,
  selectUerResResourceList,
  getResouceNumByTypeApi,
  getClubResouceNumByTypeApi,
  getStanderClubResouceNumByTypeApi
} from '@/api/resources'

const baseCourse = {
  namespaced: true,
  state: {
    slideData: undefined, // 基础课侧边栏数据
    resource: [], // 基础课资源列表
    isLeftTrigger: false,
    isCacheType: 'nomal', // 基础课页面类型
    sideMenuStatus: {
      openName: [],
      activeName: ''
    }, // 基础课数据配置
    COURSEPARAMS: {}, // 资源请求数据
    CurrentClassName: '', // 当前课程名称
    numGroup: {}, // 资源分类数量
    courseId: undefined,
    courseName: undefined,
    chapterId: undefined,
    showPlayResources: false, // 查看资源库弹窗
    resourcesItem: undefined, // 查看的具体资源
    showOnEntry: false, // 用来控制进入页面是否立即展示弹窗
    showNewTask: false,
    showPointGuide: false,
    visibleTask: true,
    visibelToollibr: false,
    phoneUploadCourse: {
      bucket: '',
      fileUrl: '',
      key: '',
      name: '',
      size: 0,
      type: ''
    }, // 小程序上传过来的文件
    baiduImg: '',
    baiduImgKey: '',
    hideTool: false
  },
  mutations: {
    setPhoneUploadCourse (state, data) {
      state.phoneUploadCourse = data
    },
    setBaiduImg (state, data) {
      state.baiduImg = data.fileUrl
      state.baiduImgKey = data.key
    },
    setResItem (state, data) {
      state.resourcesItem = data
    },
    setToollibr (state, [data, hideTool = false]) {
      state.visibelToollibr = data
      state.hideTool = hideTool
    },
    setvisibleTask (state, data) {
      state.visibleTask = data
    },
    setPointGuide (state, data) {
      state.showPointGuide = data
    },
    setNewTask (state, data) {
      state.showNewTask = data
    },
    setPlayRes (state, data) {
      state.showPlayResources = data
    },
    setSlideDataFn (state, data) {
      state.slideData = data
    },
    setSideMenuStatusFn (state, data) {
      state.sideMenuStatus = data
      setItem('sideMenuStatus', state.sideMenuStatus ? JSON.stringify(state.sideMenuStatus) : '')
    },
    setResParamsFn (state, data) {
      state.COURSEPARAMS = Object.assign(state.COURSEPARAMS, data)
      setItem('COURSEPARAMS', JSON.stringify(state.COURSEPARAMS))
    },
    setResourceFn (state, data) {
      state.resource = data
    },
    setLeftTrigger (state, data) {
      state.isLeftTrigger = data
    },
    setPageType (state, data) {
      state.isCacheType = data
    },
    setCurrentClassName (state, data) {
      state.CurrentClassName = data
    },
    setNumGroup (state, data) {
      state.numGroup = data
    },
    // 清空基础课记录数据
    removeDataFn (state, data) {
      removeItem('sideMenuStatus')
      removeItem('COURSEPARAMS')
    },
    saveCourseInfo (state, data) {
      state.courseId = data.courseId
      setItem('COURSEID', data.courseId)
      state.chapterId = data.chapterId
      if (data.chapterId) {
        setItem('CHAPTERID', data.chapterId)
      }
    },
    setShowOnEntry (state, data) {
      state.showOnEntry = data
    }
  },
  actions: {
    // 获取基础课侧边栏数据
    getBaseCourseDataFn ({ commit, state }, courseId) {
      const COURSEPARAMS = getItem('COURSEPARAMS')
      const params = COURSEPARAMS ? (typeof COURSEPARAMS === 'string' ? JSON.parse(COURSEPARAMS) : COURSEPARAMS) : {}
      if (courseId) {
        params.courseId = courseId
      } else if (getCourseId()) {
        params.courseId = getCourseId()
      }
      if (params.courseId) {
        return treeselect(params)
          .then(res => {
            const slideData = res.data.data
            commit('setSlideDataFn', slideData)
            return slideData
          })
          .then((chapterList) => {
            let sideMenuStatus = getItem('sideMenuStatus')
            sideMenuStatus = sideMenuStatus === '' ? '' : (typeof sideMenuStatus === 'string' ? JSON.parse(sideMenuStatus) : sideMenuStatus)
            const _sideMenuStatus = {}
            let currentClassName = ''
            if (typeof sideMenuStatus === 'string' || !sideMenuStatus || sideMenuStatus === '' || !sideMenuStatus.openName || !sideMenuStatus.openName.length) {
              const children = chapterList[0].children || [{
                id: 0,
                label: ''
              }]
              _sideMenuStatus.activeName = children[0].id
              currentClassName = children[0].label
              _sideMenuStatus.openName = [chapterList[0].id]
            } else {
              const sideStatus = sideMenuStatus
              _sideMenuStatus.activeName = sideStatus.activeName
              _sideMenuStatus.openName = sideStatus.openName
              if (!chapterList.some(item => item.id === _sideMenuStatus.openName[0])) {
                _sideMenuStatus.openName = [chapterList[0].id]
                _sideMenuStatus.activeName = chapterList[0].children[0].id
                currentClassName = chapterList[0].children[0].label
              } else {
                const menu = chapterList.find(item => item.id === _sideMenuStatus.openName[0]) || []
                const section = menu.children.find(item => item.id === _sideMenuStatus.activeName) || {}
                _sideMenuStatus.openName = [menu.id]
                _sideMenuStatus.activeName = section.id
                currentClassName = section.label
              }
            }
            params.chapterId = _sideMenuStatus.activeName
            params.parentChapterId = _sideMenuStatus.openName[0]
            commit('setSideMenuStatusFn', _sideMenuStatus)
            commit('setCurrentClassName', currentClassName)
            commit('setResParamsFn', params)
            return params
          })
      }
    },
    // 获取基础课基础数据
    getResourceDataFn ({ commit, state }, args = []) {
      const params = state.COURSEPARAMS
      params.resourceQueryType = args[0] || params.resourceQueryType || 2
      params.resourceSourceType = args[1]
      params.courseType = 1
      return selectUerResResourceList(params).then(res => {
        const data = res.data.rows
        commit('setResourceFn', data)
        return data
      })
    },
    // 获取基础课分类资源数据
    getResouceNumByTypeFn ({ commit, state }, params) {
      const _params = Object.assign({}, params)
      _params.parentChapterId = undefined
      _params.resType = undefined
      // _params.couseType = params
      if (!_params.machineIden) {
        _params.machineIden = getMachineIden()
      }
      _params.resourceQueryType = params.resourceQueryType || 2
      getResouceNumByTypeApi(_params).then(res => {
        const data = res.data.data
        commit('setNumGroup', data)
      })
    },
    // 获取课后服务分类资源数据
    getClubResouceNumByTypeFn ({ commit }, condition) {
      const params = Object.assign({}, condition)
      params.parentChapterId = undefined
      params.resType = undefined
      if (!params.machineIden) {
        params.machineIden = getMachineIden()
      }
      getClubResouceNumByTypeApi(params).then(res => {
        const data = res.data.data
        commit('setNumGroup', data)
      })
    },
    // 获取课后服务分类资源数据
    getStanderClubResouceNumByTypeFn ({ commit }, condition) {
      const params = Object.assign({}, condition)
      params.parentChapterId = undefined
      params.resType = undefined
      getStanderClubResouceNumByTypeApi(params).then(res => {
        const data = res.data.data
        commit('setNumGroup', data)
      })
    }
  }
}

export default baseCourse
