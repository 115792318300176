/**
 * show:
  * -1: 隐藏
  * 0: 锁定
  * 1: 显示
*/

import HomeIcon from '@/assets/navIcon/home-0_icon.png'
import HomeIconActive from '@/assets/navIcon/home-1_icon.png'
import BaseIcon from '@/assets/navIcon/base-0_icon.png'
import BaseIconActive from '@/assets/navIcon/base-1_icon.png'
import ClubIcon from '@/assets/navIcon/club-0_icon.png'
import ClubIconActive from '@/assets/navIcon/club-1_icon.png'
import CloudIcon from '@/assets/navIcon/cloud-0_icon.png'
import CloudIconActive from '@/assets/navIcon/cloud-1_icon.png'
import LabIcon from '@/assets/navIcon/lab-0_icon.png'
import LabIconActive from '@/assets/navIcon/lab-1_icon.png'
import PersonInfo from '@/assets/navIcon/center-0_icon.png'
import PersonInfoActive from '@/assets/navIcon/center-1_icon.png'
import TeacherCourseIcon from '@/assets/navIcon/teach-0_icon.png'
import TeacherCourseIconActive from '@/assets/navIcon/teach-1_icon.png'
import LiveIcon from '@/assets/navIcon/live-0_icon.png'
import LiveIconActive from '@/assets/navIcon/live-1_icon.png'
import SchoolResIcon from '@/assets/navIcon/school-0_icon.png'
import SchoolResIconActive from '@/assets/navIcon/school-1_icon.png'
import ExperimentIcon from '@/assets/navIcon/experiment-0.png'
import ExperimentIconActive from '@/assets/navIcon/experiment-1.png'
import MallIcon from '@/assets/navIcon/mall-0_icon.png'
import MallIconActive from '@/assets/navIcon/mall-1_icon.png'
import ExpertIcon from '@/assets/navIcon/expert-1_icon.png'
import ExpertIconActive from '@/assets/navIcon/expert-0_icon.png'
import PaperIcon from '@/assets/icons/paper-icon.png'
import PaperIconActive from '@/assets/icons/paper-icon-selected.png'
import AIIcon from '@/assets/navIcon/ai-0_icon.png'
import AIIconActive from '@/assets/navIcon/ai-1_icon.png'
import RouteConfig from '../../router/config'
import { getUserAuthApi } from '@/api/myinfo'
// import { getMenuListApi } from '@/api/system'
import { setItem } from '../../storage/viewStorage'
import * as pageInfo from '@/config/page.js'
import pageConfig from '@/config/url.js'
const href = location.host

const pageData = pageInfo[pageConfig[href] || 'normal']

const getRouteArrByPermissionFn = (permission, routeArr) => {
  let res = routeArr
  res = routeArr.filter(val => permission[val.permission] && permission[val.permission].show >= 0)
  res.forEach(val => {
    const childs = permission[val.permission].childs
    if (childs && childs.length) {
      val.redirect = childs[0].path
    }
  })
  return res
}

const menus = pageData.menus
const initState = {
  menus: {},
  initMenus: [],
  schlSpace: false, // 学校空间
  areaId: '-1', // 区域空间
  comboConfigType: '1', // 套餐类型 -0: 普通用户； 1 :个人注册用户，2: 学校套餐， 3: 区域套餐
  comboName: '', // 套餐名称
  isMask: false,
  platformName: undefined, // 平台名称
  platformLogo: undefined, // 平台logo
  showCast: false,
  accountEndTime: '',
  isSetMenuPermission: false,
  homePageName: 'Home',
  topConfig: []
}
// 权限控制
const permission = {
  namespaced: true,
  state: Object.assign({}, initState),
  mutations: {
    // 初始化菜单权限
    initState (state) {
      for (const i in initState) {
        if (i === 'menus') {
          const str = JSON.stringify(menus)
          state[i] = JSON.parse(str)
        } else {
          state[i] = initState[i]
        }
      }
    },
    // 设置校本空间
    setSchlSpace (state, value) {
      state.schlSpace = value
    },
    // 设置菜单权限
    setMenuPermission (state, data) {
      const _menus = Object.assign({}, state.menus)
      const _data = data.filter(val => val.moduleCode !== 'top_func')
      const topConfig = data.filter(val => val.moduleCode === 'top_func')
      state.topConfig = topConfig && topConfig[0] && topConfig[0].menuList
      _data.forEach(element => {
        if (element.moduleCode in _menus) {
          if (_menus[element.moduleCode]) {
            _menus[element.moduleCode].show = element.showFlag ? element.showFlag : -1
            _menus[element.moduleCode].name = element.moduleAlias || element.moduleName || state[element.moduleCode].name
            _menus[element.moduleCode].childs = element.menuList && element.menuList.sort((a, b) => a.sortNo - b.sortNo)
          }
        }
      })
      state.isSetMenuPermission = true
      state.menus = _menus
    },
    setIsSetMenuPermission (state, data) {
      state.isSetMenuPermission = data
    },
    // 设置区域空间权限
    setAreaSpace (state, value) {
      state.areaId = value
    },
    // 设置用户类型
    setComboConfigType (state, value) {
      state.comboConfigType = value
    },
    // 套餐名称
    setComboName (state, value) {
      state.comboName = value
    },
    // 设置平台名称
    setPlatformName (state, value) {
      state.platformName = value
    },
    // 设置平台logo
    setPlatformLogo (state, value) {
      state.platformLogo = value
    },
    // 设置用户过期提示框
    setIsMask (state, value) {
      state.isMask = value
    },
    // 设置用户展示科协模块
    setShowCast (state, value) {
      state.showCast = !!value
    },
    setAccountEndTime (state, value) {
      const date = new Date(value)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      state.accountEndTime = `${year}年${month > 9 ? month : '0' + month}月${day > 9 ? day : '0' + day}日`
    },
    updateInitMenus (state, value) {
      state.initMenus = value
    },
    getHome (state, value) {
      const menusObj = state.menus
      const menus = []
      Object.keys(menusObj).forEach(key => {
        if (menusObj[key].show > 0) {
          menus.push(menusObj[key].componentName)
        }
      })
      state.homePageName = menus[0]
    }
  },
  actions: {
    // 请求用户菜单权限
    getUserAuthFn ({ commit, state }) {
      commit('initState')
      return getUserAuthApi().then(res => {
        const data = (res.data && res.data.data && res.data.data) || {}
        let menuPermission = data.ownerModuleList || []
        const comboConfigType = data.comboConfigType || '1'
        const comboName = data.comboName
        menuPermission = menuPermission.filter(val => val.showFlag === 1)
        // console.log(222, menuPermission)
        // menuPermission.push({
        //   moduleAlias: null,
        //   moduleCode: 'paper',
        //   moduleName: '试题/试卷',
        //   ownerId: '100001',
        //   ownerType: 2,
        //   showFlag: 1
        // })
        if (data.comboConfigType) {
          commit('setAreaSpace', data.areaId)
          commit('setMenuPermission', menuPermission)
          commit('setComboConfigType', comboConfigType)
          commit('setComboName', comboName)
          setItem('comboConfigType', comboConfigType)
          commit('setSchlSpace', data.hasSchoolSpace)
          commit('setPlatformLogo', data.logoPathUrl)
          commit('setPlatformName', data.platformAlias)
          commit('setShowCast', data.ifShowCast)
          commit('setAccountEndTime', data.svrEndTime)
        } else {
          commit('setComboConfigType', '0')
          setItem('comboConfigType', comboConfigType)
        }
        if (data.comboConfigType < 2) {
          return RouteConfig
        } else {
          commit('getHome')
        }
        return getRouteArrByPermissionFn(state.menus, RouteConfig)
      })
    },
    // 根据后端配置初始化菜单
    initMenuFn ({ commit, state }) {
      const menu = [
        {
          path: '/home',
          name: 'mainPage',
          text: '首页',
          icon: HomeIcon,
          activeIcon: HomeIconActive,
          permission: 'home'
        },
        {
          path: '/science',
          name: 'science',
          text: '基础课',
          icon: BaseIcon,
          activeIcon: BaseIconActive,
          permission: 'base_course'
        },
        {
          path: '/schoolRes',
          name: 'schoolRes',
          text: '校本教材',
          icon: SchoolResIcon,
          activeIcon: SchoolResIconActive,
          permission: 'schl_course'
        },
        {
          path: '/resources/search',
          name: 'search',
          text: '云资源库',
          icon: CloudIcon,
          activeIcon: CloudIconActive,
          permission: 'res_repo'
        },
        {
          path: '/teacherCourse',
          name: 'teacherCourse',
          text: '教学成长',
          icon: TeacherCourseIcon,
          activeIcon: TeacherCourseIconActive,
          permission: 'edu_training'
        },
        {
          path: '/live',
          name: 'live',
          text: '直播天地',
          icon: LiveIcon,
          activeIcon: LiveIconActive,
          permission: 'edu_live'
        },
        {
          path: '/experiment',
          name: 'experiment',
          text: '实验专区',
          icon: ExperimentIcon,
          activeIcon: ExperimentIconActive,
          permission: 'edu_experiment'
        },
        {
          path: '/AI',
          name: 'AI',
          text: 'AI助手',
          icon: AIIcon,
          activeIcon: AIIconActive,
          permission: 'edu_AI'
        },
        {
          path: '/societies',
          name: 'societies',
          text: '课后服务',
          icon: ClubIcon,
          activeIcon: ClubIconActive,
          permission: 'club_course'
        },
        {
          path: '/lab',
          name: 'lab',
          text: '亲子任务',
          icon: LabIcon,
          activeIcon: LabIconActive,
          permission: 'edu_lab'
        },
        {
          path: '/mall',
          name: 'mall',
          text: '积分商城',
          icon: MallIcon,
          activeIcon: MallIconActive,
          permission: 'edu_mall'
        },
        {
          path: '/expert',
          name: 'expert',
          text: '专家专栏',
          icon: ExpertIcon,
          activeIcon: ExpertIconActive,
          permission: 'edu_expert'
        },
        {
          path: '/iac/paper',
          name: 'iacPaper',
          text: '试卷试题',
          icon: PaperIcon,
          activeIcon: PaperIconActive,
          permission: 'edu_paper'
        },
        {
          path: '/ucenter',
          name: 'ucenter',
          text: '个人中心',
          icon: PersonInfo,
          activeIcon: PersonInfoActive,
          permission: 'ucenter'
        }
      ]
      commit('updateInitMenus', menu)
      // return getMenuListApi().then(res => {
      //   const menuConfig = res.data.data
      //   const _res = []
      //   menu.forEach(function (val) {
      //     const i = menuConfig.findIndex(ele => (val.name === ele.component) && ele.status === '0')
      //     if (i > -1) {
      //       const _menus = menuConfig[i]
      //       val.moduleCode = _menus.moduleCode
      //       val.sortNo = _menus.sortNo
      //       val.text = _menus.menuName
      //       _res.push(val)
      //     }
      //   })
      //   _res.sort((a, b) => a.sortNo - b.sortNo)
      //   commit('updateInitMenus', _res)
      // })
    }
  },
  getters: {
    // 获取某个选项是否隐藏
    permissionRes: state => val => state.menus[val].show,
    // 是否有校本空间权限
    getSchlSpace: state => state.schlSpace,
    // 头部资源类型切换
    getTopNavArr: state => {
      let res = []
      if (state.comboConfigType !== '4') {
        res.push({
          label: '个人资源库',
          id: 3
        })
      }
      if (state.schlSpace) {
        res.push(
          {
            label: '校本资源库',
            id: 0
          }
        )
      }
      // 1. comboConfigType: 2 学校， 3 区域， 4 合作伙伴
      if (state.comboConfigType === '3' || state.comboConfigType === '4' || (state.comboConfigType === '2' && state.areaId && state.areaId.length > 3)) {
        res.push(
          {
            label: '区域资源库',
            id: 2
          }
        )
      }
      res.push(
        {
          label: '全网资源库',
          id: 1
        }
      )
      if (pageConfig[href] !== 'normal') {
        res = [
          {
            label: '个人资源库',
            id: 3
          },
          {
            label: '全网资源库',
            id: 1
          }
        ]
      }
      return res
    },
    // 获取是否有校本空间
    getIsArea: state => state.areaId && state.areaId.length > 3,
    // 获取校本空间id
    getAreaId: state => (state.areaId && state.areaId.length > 3) ? state.areaId : undefined,
    // 根据权限获取菜单
    getMensByPermission: state => value => {
      let _menus = []
      _menus = value.filter(val => state.menus[val.permission].show === 1).map(val => {
        val.text = (state.menus[val.permission].moduleName || state.menus[val.permission].name)
        return val
      })
      return _menus
    },
    // 获取课后服务
    getClubNav: state => value => {
      let res = value
      if (state.comboConfigType <= 1) {
        res = value.filter(val => val.name === '课程库')
      }
      return res
    },
    // 获取第一级菜单
    getMenuListFn: state => {
      let _menus = []
      const res = []
      state.initMenus.forEach(val => {
        const _res = state.menus[val.permission] ? state.menus[val.permission].show : -1
        if (val.moduleCode !== 'edu_common') {
          val.text = state.menus[val.permission] && (state.menus[val.permission].moduleName || state.menus[val.permission].name)
        }
        if (_res !== -1) {
          val.isAuth = _res !== 0
          res.push(val)
        } else if (state.comboConfigType < 2 && val.text) {
          val.isAuth = false
          res.push(val)
        }
      })
      if (state.comboConfigType === '4') {
        _menus = res.filter(val => val.isAuth)
      } else {
        _menus = res
      }
      return _menus
    },
    getSecondNavFn: state => (name, arr) => {
      let res = arr
      const menu = state.menus[name]
      if (menu && menu.childs && menu.childs.length) {
        res = arr.map(val => {
          const childs = menu.childs.filter(ele => !!ele)
          const findRes = childs.find(ele => ele.path === val.path)
          if (findRes) {
            val.isAuth = true
            val.label = findRes && findRes.menuName
            val.name = findRes && findRes.menuName
          } else {
            val.name = val.label || val.name
            val.isAuth = false
          }
          return val
        })
      }
      let _menus = []
      if (state.comboConfigType === '4') {
        _menus = res.filter(val => val.isAuth)
      } else {
        _menus = res
      }
      return _menus
    }
  }
}

export default permission
